import * as React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../../components/Layout/Layout';
import Seo from '../../components/Seo/Seo';
import { GlobalStyle } from '.';
import styled from 'styled-components';
import { getKeywords } from '../../utils';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { BlogFooter } from '../../components/BlogFooter/BlogFooter';

function BlogPost({ data }) {
  const post = data.markdownRemark.frontmatter;
  const featuredImg = getImage(post.featuredImage?.childImageSharp?.gatsbyImageData);
  return (
    <Layout>
      {GlobalStyle}
      <Container>
        <h1>{post.title}</h1>
        <div>
          <em>{post.date}</em> - {post.topic}
        </div>
        <GatsbyImage
          image={featuredImg}
          style={{
            marginBottom: 10,
            borderRadius: 10
          }}
          alt={post.title}
        />
        <div style={{ marginTop: 10 }} dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
        <div style={{ alignSelf: 'flex-end' }}>
          <Link to="/blog">Go back to "Blog"</Link>
        </div>
      </Container>
      <BlogFooter />
    </Layout>
  );
}

export default BlogPost;

const Container = styled.article`
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

export const query = graphql`
  query BlogPost($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      excerpt
      rawMarkdownBody
      frontmatter {
        title
        topic
        date(formatString: "MMMM DD, YYYY")
        featuredImage {
          childImageSharp {
            gatsbyImageData(width: 900)
          }
        }
      }
    }
    site: site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

export const Head = ({ data }) => {
  return (
    <Seo
      title={data.markdownRemark.frontmatter.title}
      description={data.markdownRemark.excerpt}
      image={data.site.siteMetadata.siteUrl + data.markdownRemark.frontmatter.featuredImage?.childImageSharp?.gatsbyImageData.images.fallback.src}
      keywords={getKeywords(data.markdownRemark.rawMarkdownBody)}
    />
  );
};
